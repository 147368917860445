import { FC, ReactElement, SVGProps } from "react";

export const UndrawSecureServer: FC<SVGProps<SVGSVGElement>> = (
  props: SVGProps<SVGSVGElement>
): ReactElement<SVGProps<SVGSVGElement>> => {
  return (
    <svg
      data-name="Layer 1"
      viewBox="0 0 525.01428 444.30313"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M523.59 442.908l1.224-.024a284.08 284.08 0 00-4.348-40.598c-5.297-28.713-14.104-47.57-26.178-56.047l-.704 1.002c28.205 19.803 29.991 94.91 30.005 95.667zM508.278 442.615l1.225-.024c-.027-1.356-.792-33.326-13.378-42.163l-.703 1.003c12.078 8.48 12.85 40.858 12.856 41.184z"
        fill="#f1f1f1"
      />
      <circle cx="486.616" cy="341.228" fill="#f1f1f1" r="6.125" />
      <circle cx="489.649" cy="393.899" fill="#f1f1f1" r="6.125" />
      <path
        d="M511.133 351.024a27.205 27.205 0 001.849 13.928 24.793 24.793 0 004.168-26.624 27.205 27.205 0 00-6.017 12.696zM491.113 368.88a27.205 27.205 0 0014.037.605 24.793 24.793 0 00-25.49-8.743 27.205 27.205 0 0011.453 8.137zM490.887 415.525a19.069 19.069 0 009.839.425 17.378 17.378 0 00-17.867-6.129 19.068 19.068 0 008.028 5.704zM1.978 442.908L.2 442.873a412.331 412.331 0 016.311-58.927c7.688-41.675 20.473-69.045 37.998-81.35l1.02 1.455C4.591 332.794 2 441.81 1.978 442.908z"
        fill="#f1f1f1"
      />
      <circle cx="55.644" cy="295.323" fill="#f1f1f1" r="8.89" />
      <path
        d="M429.367 250.032H202.495a6.841 6.841 0 01-6.833-6.833v-76.535a6.841 6.841 0 016.833-6.834h226.872a6.841 6.841 0 016.833 6.834v76.535a6.841 6.841 0 01-6.833 6.833z"
        fill="#e5e5e5"
      />
      <path d="M231.196 180.331h60.135v6.833h-60.135z" fill="#fff" />
      <circle cx="370.599" cy="184.431" fill="#164e63" r="4.1" />
      <circle cx="384.266" cy="184.431" fill="#164e63" r="4.1" />
      <circle cx="397.933" cy="184.431" fill="#164e63" r="4.1" />
      <path
        d="M429.367 347.068H202.495a6.841 6.841 0 01-6.833-6.834V263.7a6.841 6.841 0 016.833-6.833h226.872a6.841 6.841 0 016.833 6.833v76.535a6.841 6.841 0 01-6.833 6.834z"
        fill="#e5e5e5"
      />
      <path d="M231.196 277.366h60.135v6.833h-60.135z" fill="#fff" />
      <circle cx="370.599" cy="281.466" fill="#164e63" r="4.1" />
      <circle cx="384.266" cy="281.466" fill="#164e63" r="4.1" />
      <circle cx="397.933" cy="281.466" fill="#164e63" r="4.1" />
      <path
        d="M429.367 444.103H202.495a6.841 6.841 0 01-6.833-6.833v-76.535a6.841 6.841 0 016.833-6.834h226.872a6.841 6.841 0 016.833 6.834v76.535a6.841 6.841 0 01-6.833 6.833z"
        fill="#e5e5e5"
      />
      <path d="M231.196 374.402h60.135v6.833h-60.135z" fill="#fff" />
      <circle cx="370.599" cy="378.502" fill="#164e63" r="4.1" />
      <circle cx="384.266" cy="378.502" fill="#164e63" r="4.1" />
      <circle cx="397.933" cy="378.502" fill="#164e63" r="4.1" />
      <path
        d="M157.122 279.078a51.658 51.658 0 01-12.806-1.656l-.825-.228-.764-.384C115.079 262.95 91.755 244.64 73.4 222.39a206.016 206.016 0 01-35.004-62.156A239.23 239.23 0 0124.869 75.96c.012-.602.021-1.066.021-1.387 0-13.939 7.738-26.17 19.712-31.159 9.165-3.818 92.375-37.996 98.387-40.466 11.323-5.673 23.402-.938 25.335-.11 4.335 1.773 81.258 33.235 97.881 41.15 17.132 8.159 21.703 22.814 21.703 30.187 0 33.382-5.782 64.58-17.184 92.727a214.708 214.708 0 01-38.585 62.184c-31.498 35.446-63.004 48.012-63.308 48.123a34.427 34.427 0 01-11.71 1.87zm-7.41-18.353c2.732.613 9.02 1.531 13.12.036 5.207-1.9 31.577-15.574 56.22-43.305 34.047-38.315 51.322-86.48 51.348-143.157-.061-1.149-.877-9.338-11.722-14.503-16.3-7.762-96.255-40.455-97.061-40.785l-.221-.093c-1.676-.702-7.008-2.181-10.684-.255l-.736.343c-.891.366-89.22 36.644-98.64 40.57-6.59 2.745-8.938 9.547-8.938 14.997 0 .399-.01.978-.025 1.727-.752 38.78 8.222 134.208 107.34 184.425z"
        fill="#3f3d56"
      />
      <path
        d="M146.65 10.908S57.52 47.516 47.97 51.495c-9.55 3.979-14.326 13.529-14.326 23.078 0 9.55-7.162 134.162 113.006 194.412 0 0 10.907 3.018 19.181 0 8.275-3.018 113.323-53.95 113.323-194.81 0 0 0-14.324-16.713-22.282-16.712-7.959-97.512-40.985-97.512-40.985s-9.923-4.377-18.279 0z"
        fill="#164e63"
      />
      <path
        d="M156.2 39.557v195.289s89.927-43.288 89.131-157.09z"
        opacity=".2"
      />
    </svg>
  );
};
