import { FC, ReactElement, SVGProps } from "react";
import tw from "tailwind-styled-components";
export interface UndrawAgreementProps {
  primaryColor?: string;
  secondaryColor?: string;
}

//TODO: FIXME: NO ANY
const FadeInLeft = tw.g<any>`animate-fadeInLeft`;
const FadeIn = tw.path<any>`animate-fadeIn`;

export const UndrawAccount: FC<
  SVGProps<SVGSVGElement> & UndrawAgreementProps
> = ({
  primaryColor,
  secondaryColor,
  ...props
}: SVGProps<SVGSVGElement> & UndrawAgreementProps): ReactElement<
  SVGProps<SVGSVGElement>
> => {
  return (
    <svg
      fill="none"
      viewBox="0 0 592 438"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="undraw_account">
        <g id="doc">
          <path
            d="M586.47 437.184H127.203C125.788 437.182 124.431 436.619 123.43 435.619C122.429 434.618 121.867 433.261 121.865 431.846V5.338C121.867 3.92284 122.429 2.56617 123.43 1.56551C124.431 0.564838 125.788 0.00185213 127.203 0H586.47C587.885 0.00185213 589.241 0.564838 590.242 1.56551C591.243 2.56617 591.806 3.92284 591.808 5.338V431.845C591.806 433.26 591.243 434.617 590.242 435.618C589.242 436.619 587.885 437.181 586.47 437.183V437.184ZM127.203 2.131C126.355 2.13285 125.542 2.47058 124.942 3.0703C124.342 3.67001 124.005 4.48287 124.003 5.33099V431.845C124.005 432.693 124.342 433.506 124.942 434.106C125.542 434.705 126.355 435.043 127.203 435.045H586.47C587.318 435.043 588.131 434.706 588.731 434.106C589.33 433.506 589.668 432.693 589.67 431.845V5.338C589.668 4.48987 589.33 3.67702 588.73 3.0773C588.131 2.47759 587.318 2.13985 586.47 2.138L127.203 2.131Z"
            fill={secondaryColor ?? "#A5A5A5"}
            id="outline"
          />
          <FadeInLeft id="upperLines">
            <path
              d="M344.215 110.829C343.253 110.829 342.332 111.211 341.652 111.891C340.972 112.571 340.591 113.492 340.591 114.453C340.591 115.415 340.972 116.336 341.652 117.016C342.332 117.696 343.253 118.077 344.215 118.077H543.071C544.032 118.077 544.954 117.696 545.633 117.016C546.313 116.336 546.695 115.415 546.695 114.453C546.695 113.492 546.313 112.571 545.633 111.891C544.954 111.211 544.032 110.829 543.071 110.829H344.215Z"
              fill={secondaryColor ?? "#A5A5A5"}
              id="Path 142"
            />
            <path
              d="M344.215 132.575C343.253 132.575 342.332 132.957 341.652 133.637C340.972 134.317 340.591 135.238 340.591 136.2C340.591 137.161 340.972 138.082 341.652 138.762C342.332 139.442 343.253 139.824 344.215 139.824H445.735C446.696 139.824 447.618 139.442 448.297 138.762C448.977 138.082 449.359 137.161 449.359 136.2C449.359 135.238 448.977 134.317 448.297 133.637C447.618 132.957 446.696 132.575 445.735 132.575H344.215Z"
              fill={secondaryColor ?? "#A5A5A5"}
              id="Path 143"
            />
          </FadeInLeft>
          <FadeInLeft id="lowerLines">
            <path
              d="M170.408 237.829C169.447 237.829 168.525 238.211 167.846 238.891C167.166 239.571 166.784 240.492 166.784 241.453C166.784 242.415 167.166 243.336 167.846 244.016C168.525 244.696 169.447 245.077 170.408 245.077H543.264C544.225 245.077 545.147 244.696 545.827 244.016C546.506 243.336 546.888 242.415 546.888 241.453C546.888 240.492 546.506 239.571 545.827 238.891C545.147 238.211 544.225 237.829 543.264 237.829H170.408Z"
              fill={secondaryColor ?? "#A5A5A5"}
              id="Path 142_2"
            />
            <path
              d="M170.408 259.576C169.447 259.576 168.525 259.957 167.846 260.637C167.166 261.317 166.784 262.238 166.784 263.2C166.784 264.161 167.166 265.082 167.846 265.762C168.525 266.442 169.447 266.824 170.408 266.824H445.928C446.889 266.824 447.811 266.442 448.491 265.762C449.17 265.082 449.552 264.161 449.552 263.2C449.552 262.238 449.17 261.317 448.491 260.637C447.811 259.957 446.889 259.576 445.928 259.576H170.408Z"
              fill={secondaryColor ?? "#A5A5A5"}
              id="Path 143_2"
            />
            <path
              d="M170.408 280.829C169.447 280.829 168.525 281.211 167.846 281.891C167.166 282.571 166.784 283.492 166.784 284.453C166.784 285.415 167.166 286.336 167.846 287.016C168.525 287.696 169.447 288.078 170.408 288.078H543.264C544.225 288.078 545.147 287.696 545.827 287.016C546.506 286.336 546.888 285.415 546.888 284.453C546.888 283.492 546.506 282.571 545.827 281.891C545.147 281.211 544.225 280.829 543.264 280.829H170.408Z"
              fill={secondaryColor ?? "#A5A5A5"}
              id="Path 142_3"
            />
            <path
              d="M170.408 302.576C169.447 302.576 168.525 302.957 167.846 303.637C167.166 304.317 166.784 305.238 166.784 306.2C166.784 307.161 167.166 308.082 167.846 308.762C168.525 309.442 169.447 309.823 170.408 309.823H445.928C446.889 309.823 447.811 309.442 448.491 308.762C449.17 308.082 449.552 307.161 449.552 306.2C449.552 305.238 449.17 304.317 448.491 303.637C447.811 302.957 446.889 302.576 445.928 302.576H170.408Z"
              fill={secondaryColor ?? "#A5A5A5"}
              id="Path 143_3"
            />
          </FadeInLeft>
        </g>
        <FadeIn
          d="M540.959 387.184H431.691C430.275 387.182 428.919 386.619 427.918 385.618C426.917 384.618 426.354 383.261 426.353 381.846V364.339C426.354 362.923 426.917 361.566 427.918 360.566C428.919 359.565 430.275 359.002 431.691 359H540.959C542.374 359.002 543.731 359.565 544.731 360.566C545.732 361.566 546.295 362.923 546.297 364.339V381.846C546.295 383.261 545.732 384.618 544.731 385.618C543.731 386.619 542.374 387.182 540.959 387.184Z"
          fill={primaryColor ?? "#164E63"}
          id="sign"
        />
        <FadeInLeft id="data">
          <path
            d="M293.959 187.183H5.69066C4.27542 187.182 2.91859 186.619 1.91786 185.618C0.917137 184.618 0.354234 183.261 0.352646 181.846V55.3385C0.354234 53.9232 0.917137 52.5664 1.91786 51.5657C2.91859 50.565 4.27542 50.0021 5.69066 50.0005H293.959C295.374 50.0021 296.731 50.565 297.731 51.5657C298.732 52.5664 299.295 53.9232 299.297 55.3385V181.846C299.295 183.261 298.732 184.618 297.731 185.618C296.731 186.619 295.374 187.182 293.959 187.183Z"
            fill={primaryColor ?? "#164E63"}
            id="Path 154"
          />
          <path
            d="M136.89 94.37C135.638 94.374 134.625 95.9997 134.628 98.0011C134.63 99.997 135.642 101.614 136.89 101.618H243.759C245.011 101.614 246.024 99.9883 246.022 97.9868C246.019 95.9909 245.008 94.3739 243.759 94.37H136.89Z"
            fill="white"
            id="Path 155"
          />
          <path
            d="M136.89 115.37C135.638 115.374 134.625 117 134.628 119.001C134.63 120.997 135.642 122.614 136.89 122.618H243.759C245.011 122.614 246.024 120.988 246.022 118.987C246.019 116.991 245.008 115.374 243.759 115.37H136.89Z"
            fill="white"
            id="Path 156"
          />
          <path
            d="M136.89 136.116C135.638 136.12 134.625 137.746 134.628 139.747C134.63 141.743 135.642 143.36 136.89 143.364H182.876C184.128 143.36 185.141 141.734 185.138 139.733C185.136 137.737 184.124 136.12 182.876 136.116H136.89Z"
            fill="white"
            id="Path 157"
          />
          <path
            d="M82.6276 147.592C98.6439 147.592 111.628 134.608 111.628 118.592C111.628 102.576 98.6439 89.592 82.6276 89.592C66.6114 89.592 53.6276 102.576 53.6276 118.592C53.6276 134.608 66.6114 147.592 82.6276 147.592Z"
            fill="white"
            id="Vector"
          />
        </FadeInLeft>
      </g>
    </svg>
  );
};
