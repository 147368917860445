import { FC, ReactElement, SVGProps } from "react";

export const UndrawAgreement: FC<SVGProps<SVGSVGElement>> = (
  props: SVGProps<SVGSVGElement>
): ReactElement<SVGProps<SVGSVGElement>> => {
  return (
    <svg
      data-name="Layer 1"
      viewBox="0 0 746 721.343"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M680.602 720.623v-72.34s28.192 51.286 0 72.34z" fill="#f1f1f1" />
      <path
        d="M682.344 720.61l-53.29-48.92s56.845 13.915 53.29 48.92z"
        fill="#f1f1f1"
      />
      <path
        d="M494.388 319.19L211.602 484.466a4.326 4.326 0 01-5.91-1.55L23.841 171.779a4.326 4.326 0 011.55-5.911L308.177.59a4.326 4.326 0 015.912 1.55l181.849 311.138a4.326 4.326 0 01-1.55 5.911z"
        fill="#fff"
      />
      <path
        d="M494.388 319.19L211.602 484.466a4.326 4.326 0 01-5.91-1.55L23.841 171.779a4.326 4.326 0 011.55-5.911L308.177.59a4.326 4.326 0 015.912 1.55l181.849 311.138a4.326 4.326 0 01-1.55 5.911zM26.264 167.36a2.596 2.596 0 00-.93 3.546l181.85 311.139a2.596 2.596 0 003.546.93l282.785-165.278a2.596 2.596 0 00.93-3.547L312.597 3.012a2.596 2.596 0 00-3.546-.93z"
        fill="#3f3d56"
      />
      <path
        d="M208.04 198.345l-70.137 40.992a4.326 4.326 0 01-5.911-1.55L91 167.65a4.326 4.326 0 011.55-5.911l70.136-40.992a4.326 4.326 0 015.912 1.55l40.992 70.137a4.326 4.326 0 01-1.55 5.91zM93.422 163.23a2.596 2.596 0 00-.93 3.547l40.992 70.137a2.596 2.596 0 003.547.93l70.137-40.993a2.596 2.596 0 00.93-3.546l-40.993-70.137a2.596 2.596 0 00-3.546-.93z"
        fill="#f2f2f2"
      />
      <path
        d="M192.91 218.7l-70.138 40.992a3.893 3.893 0 01-5.32-1.395L76.46 188.16a3.893 3.893 0 011.395-5.32l70.137-40.992a3.893 3.893 0 015.32 1.395l40.992 70.137a3.893 3.893 0 01-1.395 5.32z"
        fill="#164e63"
      />
      <path
        d="M362.196 165.804l-219.363 128.21a3.889 3.889 0 01-3.925-6.715l219.364-128.21a3.889 3.889 0 013.924 6.715zM322.74 219.897L156.35 317.145a3.889 3.889 0 01-3.924-6.716l166.388-97.248a3.889 3.889 0 113.924 6.716zM311.736 81.18l-96.998 56.692a3.889 3.889 0 01-3.924-6.715l96.997-56.692a3.889 3.889 0 013.925 6.716zM301.047 118.46l-72.79 42.542a3.889 3.889 0 01-3.925-6.715l72.79-42.543a3.889 3.889 0 013.925 6.715zM389.234 212.064L169.87 340.274a3.889 3.889 0 01-3.925-6.715L385.31 205.35a3.889 3.889 0 013.925 6.715zM349.777 266.157l-166.388 97.248a3.889 3.889 0 11-3.925-6.715l166.388-97.248a3.889 3.889 0 113.925 6.715zM416.271 258.325l-219.363 128.21a3.889 3.889 0 01-3.925-6.715l219.364-128.21a3.889 3.889 0 013.924 6.715zM429.79 281.455l-219.364 128.21a3.889 3.889 0 11-3.924-6.715l219.363-128.21a3.889 3.889 0 113.925 6.715z"
        fill="#ccc"
      />
      <path
        d="M358.852 371.498a84.703 84.703 0 008.202-11.47c2.161-3.65 4.282-7.55 4.759-11.83a11.683 11.683 0 00-3.499-9.985 8.222 8.222 0 00-9.646-1.097 9.809 9.809 0 00-4.923 8.76 12.959 12.959 0 005.536 9.814c6.484 4.698 16.61 4.452 23.52.877 7.785-4.026 11.806-14.561 5.691-21.773-1.272-1.5-3.389.177-3.07 1.795a8.72 8.72 0 0017.279-2.136l-2.75 1.607a20.99 20.99 0 0020.88-2.24 19.47 19.47 0 003.907-3.776c1.346-1.756 3.606-2.89 5.437-4.155l13.19-9.114c1.94-1.34.096-4.535-1.86-3.183l-11.947 8.254c-1.99 1.375-3.994 2.734-5.973 4.127-1.414.996-2.284 2.603-3.571 3.786a17.288 17.288 0 01-18.252 3.087 1.866 1.866 0 00-2.75 1.607 5.048 5.048 0 01-10.025 1.188l-3.07 1.794c3.945 4.652 1.318 11.787-3.227 14.909-5.083 3.49-12.593 4.097-18.323 1.927a10.81 10.81 0 01-6.365-5.84 6.443 6.443 0 011.462-7.373 4.623 4.623 0 016.427-.069 8.269 8.269 0 012.191 7.46c-.59 3.694-2.633 7.1-4.533 10.256a78.797 78.797 0 01-7.337 10.218c-1.522 1.8 1.103 4.394 2.64 2.575z"
        fill="#164e63"
      />
      <path
        d="M391.593 459.18a8.672 8.672 0 01.872 1.05l40.848-1 4.766-8.707 14.081 5.474-6.938 16.236a6.591 6.591 0 01-6.851 3.953l-46.151-5.575a8.649 8.649 0 11-.627-11.432zM524.753 707.256h-13.431l-6.39-51.807 19.823.001-.002 51.806z"
        fill="#ffb7b7"
      />
      <path
        d="M528.178 720.276l-43.308-.002v-.547a16.858 16.858 0 0116.857-16.857l26.452.001z"
        fill="#2f2e41"
      />
      <path
        d="M402.792 706.958l-12.351-5.277 14.475-50.152 18.229 7.788-20.353 47.641z"
        fill="#ffb7b7"
      />
      <path
        d="M400.828 720.276l-39.826-17.014.215-.504a16.858 16.858 0 0122.123-8.88l.001.001 24.324 10.392z"
        fill="#2f2e41"
      />
      <path
        d="M459.468 470.402l-.972 15.739 28.391 3.038 29.706 3.179-8.744-23.899-48.381 1.943z"
        fill="#ffb7b7"
      />
      <path
        d="M457.602 486.343l-11.029-76.887 19.117-6.265 5.614-7.7 27.323 1.616 4.012 8.986 14.569 6.884-2.239 41.582 1.633 34.784z"
        fill="#2f2e41"
      />
      <path
        d="M450.66 465.739l-19.444-7.78.083-.346c.083-.349 8.36-34.932 9.458-40.49 1.145-5.79 5.327-7.39 5.505-7.456l.13-.048 7.695 2.343 3.38 26.554zM412.796 697.853l-25.884-5.31 41.37-114.154 14.158-38.936s8.98-31.219 13.038-43.3l2.448-16.21 57.962 6.415s16.189 23.775 11.27 62.526l2.225 145.65-25.884 1.328-21.68-146.896-38.051 80.527s-11.994 34.364-20.575 41.812z"
        fill="#2f2e41"
      />
      <path
        d="M492.644 491.765a8.673 8.673 0 011.364.075l27.04-30.634-3.145-9.415 13.588-6.604 7.188 16.126a6.591 6.591 0 01-1.759 7.713l-35.467 30.052a8.649 8.649 0 11-8.809-7.313z"
        fill="#ffb7b7"
      />
      <path
        d="M518.89 464.187l-11.28-26.533 4.4-18.481 4.74-5.989a2.73 2.73 0 012.193.02c1.748.704 3.237 2.86 4.425 6.41l10.571 30.111zM460.367 385.659v-20.003a26.804 26.804 0 0153.61 0v20.003a3.605 3.605 0 01-3.602 3.6h-46.407a3.605 3.605 0 01-3.6-3.6z"
        fill="#2f2e41"
      />
      <circle cx="481.664" cy="369.142" fill="#ffb7b7" r="19.652" />
      <path
        d="M454.026 367.256a21.227 21.227 0 0121.204-21.203h4a21.227 21.227 0 0121.204 21.203v.4h-8.455l-2.884-8.075-.576 8.075h-4.37l-1.455-4.074-.29 4.074h-28.378z"
        fill="#2f2e41"
      />
      <path
        d="M479.73 391.509a3.547 3.547 0 01-.282-3.766c4.24-8.064 10.175-22.964 2.296-32.153l-.566-.66h22.874v34.341l-20.78 3.666a3.678 3.678 0 01-.637.057 3.586 3.586 0 01-2.905-1.485z"
        fill="#2f2e41"
      />
      <path
        d="M212.904 695.94l12.138 4.338 22.509-44.755-17.915-6.402-16.732 46.819z"
        fill="#9f616a"
      />
      <path
        d="M205.602 706.6l5.622-15.73 23.905 8.544a16.197 16.197 0 019.79 20.679l-.177.495z"
        fill="#2f2e41"
      />
      <path
        d="M291.836 707.946l12.89-.001 6.133-49.719-19.025.001.002 49.719z"
        fill="#9f616a"
      />
      <path
        d="M288.548 703.737H313.934a16.178 16.178 0 0116.178 16.177v.526l-41.563.001z"
        fill="#2f2e41"
      />
      <path
        d="M262.459 390.3l5.606-11.077s20.884 5.232 22.782 17.336z"
        fill="#f1f1f1"
      />
      <path
        d="M239.328 516.96l15.245 85.074-40.48 71.588 24.183 9.463 41.006-69.394 5.257-18.926 3.154 98.834 21.81-.662 9.999-92.287-16.038-85.793-64.136 2.103z"
        fill="#2f2e41"
      />
      <path
        d="M268.267 537.071c-22.396 0-36.616-4.283-36.935-4.351l-.515-.11 11.665-54.086.525-49.89c0-21.14 13.915-36.727 15.846-38.166a9.096 9.096 0 014.837-5.945c4.642-2.106 10.313.421 10.552.53l17.023 6.383.08.236c.205.605 22.628 74.214 22.628 74.214s-1.358 64.315-1.83 66.422c0 0-31.432 4.763-43.876 4.763z"
        fill="#3f3d56"
      />
      <circle cx="285.477" cy="352.727" fill="#9f616a" r="23.008" />
      <path
        d="M259.823 432.715l40.253 56.162 5.944-26.227-46.197-29.935z"
        opacity=".2"
      />
      <path
        d="M386.024 470.928a9.874 9.874 0 01-14.825-3.073l-34.647 5.546 7.506-16.617 31.75-2.719a9.927 9.927 0 0110.216 16.863z"
        fill="#9f616a"
      />
      <path
        d="M345.842 455.838l12.449-1.221 3.45 17.366-15.665.277-.234-16.422z"
        fill="#f1f1f1"
      />
      <path
        d="M356.047 478.034l-60.97-5.429-30.786-48.96-.094-.433a16.855 16.855 0 0131.466-11.286l18.072 35.25 36.473 5.514z"
        fill="#3f3d56"
      />
      <path
        d="M265.879 372.703l19.097.686c1.164.042 4.323-12.521 4.737-15.127a7.113 7.113 0 017.42-5.754c1.429.135 5.034-2.536 8.753-5.698 7.062-6.004 6.695-17.28-1.063-22.354q-.318-.208-.624-.38c-4.894-2.739-10.619-3.658-16.227-3.729-5.083-.063-10.311.577-14.79 2.983-8.03 4.314-12.302 13.74-12.678 22.848s2.545 18.012 5.858 26.503"
        fill="#2f2e41"
      />
      <path
        d="M744.602 721.343H1a1 1 0 010-2h744a1 1 0 011 1c0 .552-.845 1-1.398 1z"
        fill="#cbcbcb"
      />
    </svg>
  );
};
